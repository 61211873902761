import React, { useEffect, useState } from 'react';
import AuthCheck from '../SubComponents/AuthCheck';
import './Header.css';

function Header({ setActiveSection }) {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) { // Меняйте 100 на нужное значение
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <a href="/">
                <img src="./favicons/favicon.png" alt="Astrocreate logo" />
            </a>
            <nav>
                <a rel="noreferrer" href="#project" onClick={() => setActiveSection('project')}>О проекте</a>
                <a rel="noreferrer" href="#changelog" onClick={() => setActiveSection('changelog')}>Изменения</a>
                <a rel="noreferrer" href="#mods" onClick={() => setActiveSection('mods')}>Моды</a>
                <a rel="noreferrer" href="#play" onClick={() => setActiveSection('play')}>Играть</a>
                <a rel="noreferrer" href="#bio" onClick={() => setActiveSection('bio')}>Био</a>
                <a rel="noreferrer" href="#content" onClick={() => setActiveSection('content')}>Контент</a>
            </nav>
            <AuthCheck />
        </header>
    );
}

export default Header;
