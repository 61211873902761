import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import AuthDiscord from './AuthDiscord';  // Импортируйте новый компонент
import TRender from './render/Render';
import NotFoundPage from './NotFound/404';
import SnowEffectToggle from './components/snow/SnowEffectToggle';

const root = ReactDOM.createRoot(document.getElementById('app-mount'));
root.render(
  <React.StrictMode>
    <SnowEffectToggle/>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/@me" element={<AuthDiscord />} />
        <Route path="/@render" element={<TRender />} />
        {/* Редирект для всех неизвестных маршрутов */}
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
    </Router>
  </React.StrictMode>
);
