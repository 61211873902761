import React from 'react';
import ReactDom from 'react-dom/client';
import '../../images.css'
import DsMsgsMods from '../SubComponents/DsMsgsMods';

function Mods() {
    return (
        <>
            <h2 style={{ textAlign: 'center' }}>Моды</h2>
            
            <DsMsgsMods/>
            <h3>Если у вас консоль, то пропустите эту инструкцию.</h3>
            <p>1. Качаем по ссылке <a href="https://github.com/AstroTechies/astro_modloader/releases" target="_blank"><b>ModLoader v0.1.12</b></a>.</p>
            <p><i>(Винда может ругаться на exe`шник, т.к. он является исходным кодом проги)</i></p>

            <img loading="lazy" src="https://astrocreate.space/static/img/mod/github_ml.png" alt="Отсутствует" className="images"/>
            <p>2. Качаем моды: <a href="./mods/000-AstroChat-2.1.2_P.pak" download><b>AstroChat</b></a>, <a href="./mods/000-RocketLauncher-1.1.1_P.pak" download><b>RocketLauncher</b></a>, <a href="./mods/000-FirstPerson.Corporalwill123-1.0.0_P.pak" download><b>FirstPerson</b></a>, <a href="./mods/000-MissionBypassMod.Colson-0.3.1_P.pak" download><b>MissionBypass</b></a>, <a href="./mods/000-STORAGEX.GDutch-2.0.0_P.pak" download><b>STORAGEX</b></a></p>
            <p>3. Переносим скачанные моды в окно <b>Modloader`а</b>:</p>
            <img loading="lazy" src="https://astrocreate.space/static/img/mod/modloader.png" alt="Отсутствует" className="images"/>
            <p>4. Ждем пока установка завершится и открываем стим перед запуском <b>Astroneer`а</b> через <b>Modloader.</b></p>
            <p>5. Читаем и выполняем инструкцию по заходу на сервер, она ниже.</p>
        </>
    )
};

export default Mods;