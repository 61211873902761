import React, { useState, useEffect } from 'react';

const clientId = '1260861319561613355';
const clientSecret = '9au0xeku8LyAenJbm9U_8EDeIWn-LQgR'; // Не используйте это в клиентском коде для продакшн
const redirectUri = 'http://localhost:3000/@me'; // Обновите при необходимости

function DiscordOAuth() {
  const [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');
    const storedAccessToken = localStorage.getItem('access_token');
    const storedUserInfo = localStorage.getItem('user_info');

    console.log(`https://discord.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=identify`);

    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    } else if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        setCode(code);
      } else {
        window.location.href = `https://discord.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=presences.read+presences.write+identify`;
      }
    }
  }, []);

  useEffect(() => {
    if (code) {
      const getAccessToken = async (code) => {
        const tokenUrl = 'https://discord.com/api/oauth2/token';
        const body = new URLSearchParams({
          client_id: clientId,
          client_secret: clientSecret,
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: redirectUri,
        });

        const response = await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: body.toString(),
        });

        const data = await response.json();
        if (data.access_token) {
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token); // Сохраняем refresh token в localStorage

          // Получаем информацию о пользователе
          const userUrl = 'https://discord.com/api/v10/users/@me';
          const userResponse = await fetch(userUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          });

          const userData = await userResponse.json();
          localStorage.setItem('user_info', JSON.stringify(userData)); // Сохраняем информацию о пользователе
          setUserInfo(userData);
          console.log(userData)
        }
        
      };

      getAccessToken(code);
    }
  }, [code]);

  return (
    <div>
      <h1 style={{color: 'white'}}>Discord OAuth2 User Information</h1>
      {!userInfo && !accessToken && (
        <p>Пожалуйста, подождите, пока мы проверяем вашу авторизацию...</p>
      )}
      {userInfo && (
        <div>
          <h2>Welcome, {userInfo.username}!</h2>
          <img width={100} src={`https://cdn.discordapp.com/avatars/${userInfo.id}/${userInfo.avatar}.png`} alt="Avatar" />
        </div>
      )}
    </div>
      
  );
}

export default DiscordOAuth;