import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Project from './components/Project/Project';
import Mods from './components/Mods/Mods';
import Play from './components/Play/Play';
import PlayersBio from './components/playersBio/PlayersBio';
import Content from './components/Content/Content';
import Resources from './components/Resources/Resources';
import Changelog from './components/changeLog/ChangeLog';
import InstallPWAButton from './components/InstallPWAButton';
// Подкомпоненты
import Team from './components/SubComponents/Team';
import CountryInfo from './components/SubComponents/CountryInfo';
// SVG
import { ReactComponent as Discord } from './assets/svg/discord.svg';
import { ReactComponent as Telegram } from './assets/svg/telegram.svg';
import { ReactComponent as Youtube } from './assets/svg/youtube.svg';
import { ReactComponent as Twitch } from './assets/svg/twitch.svg';
import { ReactComponent as Boosty } from './assets/svg/boosty.svg';


const fetchPlayerCount = async () => {
  try {
    const response = await fetch('https://worker.astrocreate.space/get_player_role');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Ошибка при получении числа игроков:', error);
    return { status: 'error', count: 0 }; // Default value
  }
};

function handleClick(event) {
  const { id } = event.target;

  switch (id) {
    case 'discord':
      window.open(`https://discord.com/invite/48fZPbMEFt`, `_blank`);
      break;
    case 'telegram':
      window.open(`https://t.me/podval_ostapa`, `_blank`);
      break;
    case 'boosty':
      window.open(`https://boosty.to/ostap_india`, `_blank`);
      break;
    case 'youtube':
      window.open(`https://www.youtube.com/@ostap_india?sub_confirmation=1`, `_blank`);
      break;
    case 'twitch':
      window.open(`https://twitch.tv/ostap_india`, `_blank`);
      break;
    default:
      break;
  }
}

function App() {
  const [playerCount, setPlayerCount] = useState(0);
  const [maxCount, setMaxCount] = useState(70); // Default value
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const loadPlayerCount = async () => {
      const data = await fetchPlayerCount();
      if (data.status === 'success') {
        const count = data.count;
        setMaxCount(count);

        // Animate number from 0 to count
        let start = 0;
        const end = count;
        const duration = 1500; // Animation duration in ms
        const stepTime = 50; // Update interval in ms
        const steps = Math.ceil(duration / stepTime);
        const stepValue = end / steps;

        const interval = setInterval(() => {
          start += stepValue;
          if (start >= end) {
            setPlayerCount(end);
            clearInterval(interval);
          } else {
            setPlayerCount(Math.round(start));
          }
        }, stepTime);
      }
    };
    loadPlayerCount();
  }, []);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    setActiveSection(hash || 'project'); // Use 'project' as default section if hash is empty
  }, []);

  useEffect(() => {
    if (activeSection) {
      window.location.hash = activeSection;
    }
  }, [activeSection]);

  return (
    <>
      <Header setActiveSection={setActiveSection} />
      <main>
        <aside className="sidebars" style={{ textAlign: 'center' }}>
          <a href="/@render">
            <img className="avatar_img" src="/favicons/favicon.gif" alt="Astrocreate 1x1 logo" />
          </a>
          <h2 className="player_count">{playerCount}</h2>
          <p className="player_text">ИГРОКОВ С РОЛЬЮ</p>
          <h3>--</h3>
          <p style={{fontSize: '18px'}}>Режим: --</p>
          
          <p className="buttons-flex">
            <button id="discord" className="button" onClick={handleClick}><Discord/>Дискорд</button>
            <button id="telegram" className="button" onClick={handleClick}><Telegram/>Телеграм</button>
            <button id="boosty" className="button" onClick={handleClick}><Boosty/>Бусти</button>
            <button id="youtube" className="button" onClick={handleClick}><Youtube/>Ютуб</button>
            <button id="twitch" className="button" onClick={handleClick}><Twitch/>Твич</button>
          </p>

        </aside>

        <aside className="center_sidebar">
          {activeSection === 'project' && <Project />}
          {activeSection === 'mods' && <Mods />}
          {activeSection === 'play' && <Play />}
          {activeSection === 'bio' && <PlayersBio />}
          {activeSection === 'content' && <Content />}
          {activeSection === 'resources' && <Resources />}
          {activeSection === 'changelog' && <Changelog />}
        </aside>

        <aside className="sidebars">
      
          <h2>Дебаг</h2>
          <p>Здесь ещё должен быть график из тестового проекта.</p>
          <CountryInfo />
          <a className='resources' href="#resources" onClick={() => setActiveSection('resources')}>Ресурсы</a>
          <Team />
        </aside>
      </main>
    </>
  );
}

export default App;
