import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber'; 
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import './Animation.css'; 

// Определите данные планет
const planets = [
  { name: 'Glacio', radius: 1, position: [-5, 0, 0], color: 0x00cdff, emissive: 0x00cdff },
  { name: 'Desolo', radius: 0.5, position: [5, 0, 0], color: 0x9bb870, emissive: 0x9bb870 },
  { name: 'Vesania', radius: 1, position: [0, 0, -5], color: 0xfe00fe, emissive: 0xfe00fe },
  { name: 'Calidor', radius: 1, position: [0, 0, 5], color: 0xe76600, emissive: 0xe76600 },
  { name: 'Atrox', radius: 1, position: [0, -5, 0], color: 0x7fcd00, emissive: 0x7fcd00 },
  { name: 'Novus', radius: 0.5, position: [0, 5, 0], color: 0xdd9aff, emissive: 0xdd9aff },
  { name: 'Sylva', radius: 1, position: [0, 0, 0], color: 0x12a162, emissive: 0x12a162 },
  { name: 'Sun room', radius: 0.5, position: [-10, 0, 0], color: 0xffffff, emissive: 0xffffff },
];

// Компонент планеты
function Planet({ position, radius, color, emissive }) {
  const meshRef = useRef(null);

  return (
    <mesh ref={meshRef} position={position}>
      <sphereGeometry args={[radius, 32, 32]} />
      <meshStandardMaterial color={color} emissive={emissive} emissiveIntensity={1} />
    </mesh>
  );
}

// Компонент управления
function Controls() {
  const { camera, gl } = useThree(); 

  useEffect(() => {
    if (camera && gl) {
      const controls = new OrbitControls(camera, gl.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;

      return () => {
        controls.dispose();
      };
    }
  }, [camera, gl]);

  return null;
}

// Компонент сцены
function Scene() {
  return (
    <>
      {planets.map((planet) => (
        <Planet
          key={planet.name}
          position={planet.position}
          radius={planet.radius}
          color={planet.color}
          emissive={planet.emissive}
        />
      ))}
      <Controls />
      <EffectComposer>
        <Bloom intensity={0.8} width={500} height={500} kernelSize={2} />
        <Bloom intensity={0.1} width={500} height={500} kernelSize={4} />
      </EffectComposer>
    </>
  );
}

function App() {
  const [showAnimation, setShowAnimation] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingComplete(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (loadingComplete) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [loadingComplete]);

  return (
    <>
      {showAnimation && (
        <div className="fullscreen-animation">
          <img src="./favicons/favicon.gif" alt="Rocket" className="rocket" />
        </div>
      )}
      <Canvas
        camera={{ position: [-10, 0, 10], fov: 75 }}
        style={{ height: '100vh', width: '100%' }}
      >
        <Scene />
      </Canvas>
    </>
  );
}

export default App;
